// extracted by mini-css-extract-plugin
export var authorProfileImage = "PostCard-module--author-profile-image--EY64I";
export var cardContentWrapper = "PostCard-module--card-content-wrapper--s5Fkx";
export var defaultAvatar = "PostCard-module--default-avatar--OX35s";
export var featuredTags = "PostCard-module--featured-tags--m+UYZ";
export var postCard = "PostCard-module--post-card--BXeeZ";
export var postCardAvatar = "PostCard-module--post-card-avatar--a+xhk";
export var postCardExcerpt = "PostCard-module--post-card-excerpt--OpqKD";
export var postCardFooter = "PostCard-module--post-card-footer--sSbS+";
export var postCardFooterLeft = "PostCard-module--post-card-footer-left--0vOXX";
export var postCardFooterRight = "PostCard-module--post-card-footer-right--8mB3w";
export var postCardFooterWrapper = "PostCard-module--post-card-footer-wrapper--TQA2q";
export var postCardImage = "PostCard-module--post-card-image--F-aJW";
export var postCardTags = "PostCard-module--post-card-tags--9Uzta";
export var postCardTitle = "PostCard-module--post-card-title--esjd1";