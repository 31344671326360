import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { Tags } from "@tryghost/helpers-gatsby";
import { readingTime as readingTimeHelper } from "@tryghost/helpers";

import * as classes from "./PostCard.module.scss";

const PostCard = ({ post }) => {
    const url = `/${post.slug}/`;
    const readingTime = readingTimeHelper(post);

    return (
        <div className={classes.postCard}>
            {/* Cover Image */}
            <Link to={url}>
                {post.feature_image && (
                    <div>
                        <div
                            className={classes.postCardImage}
                            style={{
                                backgroundImage: `url(${post.feature_image})`,
                            }}
                        ></div>
                    </div>
                )}
            </Link>
            <div className={classes.cardContentWrapper}>
                <Link to={url}>
                    <header>
                        {post.featured && (
                            <span className={classes.featuredTags}>
                                Featured
                            </span>
                        )}
                        <h2 className={classes.postCardTitle}>{post.title}</h2>
                    </header>
                    <section className={classes.postCardExcerpt}>
                        {post.excerpt}
                    </section>
                </Link>
                <footer className={classes.postCardFooterWrapper}>
                    <div className={classes.postCardFooter}>
                        {/* Author Detail */}
                        <Link
                            to={`/author/${post.primary_author.slug}`}
                            className={classes.postCardFooterLeft}
                        >
                            <div className={classes.postCardAvatar}>
                                {post.primary_author.profile_image ? (
                                    <img
                                        className={classes.authorProfileImage}
                                        src={post.primary_author.profile_image}
                                        alt={post.primary_author.name}
                                    />
                                ) : (
                                    <img
                                        className={classes.defaultAvatar}
                                        src="/images/icons/avatar.svg"
                                        alt={post.primary_author.name}
                                    />
                                )}
                            </div>
                            <span>{post.primary_author.name}</span>
                        </Link>
                        <div className={classes.postCardFooterRight}>
                            <div>{readingTime}</div>
                        </div>
                    </div>
                    {post.tags && (
                        <div className={classes.postCardTags}>
                            {" "}
                            <Tags
                                post={post}
                                visibility="public"
                                autolink={true}
                                permalink="/tag/:slug"
                                separator=""
                            />
                        </div>
                    )}
                </footer>
            </div>
        </div>
    );
};

PostCard.propTypes = {
    post: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        feature_image: PropTypes.string,
        featured: PropTypes.bool,
        tags: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
            })
        ),
        excerpt: PropTypes.string.isRequired,
        primary_author: PropTypes.shape({
            name: PropTypes.string.isRequired,
            profile_image: PropTypes.string,
            slug: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
};

export default PostCard;
