import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import * as classes from "./Pagination.module.scss";

const Pagination = ({ pageContext }) => {
    const { previousPagePath, nextPagePath, humanPageNumber, numberOfPages } =
        pageContext;

    return (
        <nav className={classes.pagination} role="navigation">
            {previousPagePath && (
                <Link
                    to={previousPagePath}
                    rel="prev"
                    className={`${classes.btn} ${classes.btnPrev}`}
                >
                    Previous
                </Link>
            )}

            {numberOfPages > 1 && (
                <div className={classes.paginationLocation}>
                    Page {humanPageNumber} of {numberOfPages}
                </div>
            )}

            {nextPagePath && (
                <Link
                    to={nextPagePath}
                    rel="next"
                    className={`${classes.btn} ${classes.btnNext}`}
                >
                    Next
                </Link>
            )}
        </nav>
    );
};

Pagination.propTypes = {
    pageContext: PropTypes.object.isRequired,
};

export default Pagination;
